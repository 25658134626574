import JSBI from 'jsbi'

// exports for external consumption
export type BigintIsh = JSBI | bigint | string

export enum ChainId {
  MUMBAI = 80001,
  MATIC = 137,
  FUJI = 43113,
  AVALANCHE = 43114,
  FANTOM_TESTNET = 4002,
  FANTOM = 250,
  HUOBIECO_TESTNET = 256,
  HUOBIECO = 128,
  REEF = 13939,
  XDAI = 100,
  FUSE = 122,
  OPTIMISM = 10,
  OPTIMISM_TESTNET = 69,
  KUCOIN = 321,
  KUCOIN_TESTNET = 322,
  ETHERLITE = 111,
  ETHEREUM_CLASSIC = 61,
  ETHEREUM_CLASSIC_TESTNET = 62,
  MOONBEAM = 1284,
  MOONBEAM_TESTNET = 1287,
  CLOVER = 124,
  CLOVER_TESTNET = 123,
  CELO = 42220,
  ALFAJORES = 44787,
  ARBITRUM_TESTNET = 421611,
  ARBITRUM = 42161,
  FLARE = 14,
  FLARE_TESTNET = 16,
  FUSION = 32659,
  HARMONY = 1666600000,
  OKEX = 66,
  IOTEX = 4689,
  HOO = 70,
  ELAETH = 20,
  MOONRIVER = 1285,
  TELOS = 40,
  CRONOS = 25,
  KAVA = 2222,
  BINANCE_TESTNET = 97,
  BINANCE = 56,
  ROPSTEN = 3,
  ETHEREUM = 1,
}

export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT
}

export enum Rounding {
  ROUND_DOWN,
  ROUND_HALF_UP,
  ROUND_UP
}

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const FACTORY_ADDRESS: { [chainId in ChainId]: string } = {
  [ChainId.MUMBAI]: '0x14d280b36eb9cEfF93e9e75fd91f046c54DB2C17',
  [ChainId.MATIC]: '0xE3BD06c7ac7E1CeB17BdD2E5BA83E40D1515AF2a',
  [ChainId.FUJI]: '0x7941856Ab590351EBc48FE9b68F17A3864aB6Df5',
  [ChainId.AVALANCHE]: '0x091d35d7F63487909C863001ddCA481c6De47091',
  [ChainId.FANTOM_TESTNET]: '0x7Ba73c99e6f01a37f3e33854c8F544BbbadD3420',
  [ChainId.FANTOM]: '0x7Ba73c99e6f01a37f3e33854c8F544BbbadD3420',
  [ChainId.HUOBIECO_TESTNET]: '0x379f1437267B0F069c4856953bE43c42b03795da',
  [ChainId.HUOBIECO]: '0x997fCE9164D630CC58eE366d4D275B9D773d54A4',
  [ChainId.REEF]: ZERO_ADDRESS,
  [ChainId.XDAI]: '0xCB018587dA9590A18f49fFE2b85314c33aF3Ad3B',
  [ChainId.FUSE]: '0x779407e40Dad9D70Ba5ADc30E45cC3494ec71ad2',
  [ChainId.OPTIMISM]: '0xedfad3a0F42A8920B011bb0332aDe632e552d846',
  [ChainId.OPTIMISM_TESTNET]: ZERO_ADDRESS,
  [ChainId.KUCOIN]: '0x1f9aa39001ed0630dA6854859D7B3eD255648599',
  [ChainId.KUCOIN_TESTNET]: ZERO_ADDRESS,
  [ChainId.ETHERLITE]: ZERO_ADDRESS,
  [ChainId.ETHEREUM_CLASSIC]: ZERO_ADDRESS,
  [ChainId.ETHEREUM_CLASSIC_TESTNET]: ZERO_ADDRESS,
  [ChainId.MOONBEAM]: ZERO_ADDRESS,
  [ChainId.MOONBEAM_TESTNET]: ZERO_ADDRESS,
  [ChainId.CLOVER]: ZERO_ADDRESS,
  [ChainId.CLOVER_TESTNET]: ZERO_ADDRESS,
  [ChainId.CELO]: ZERO_ADDRESS,
  [ChainId.ALFAJORES]: ZERO_ADDRESS,
  [ChainId.ARBITRUM_TESTNET]: ZERO_ADDRESS,
  [ChainId.ARBITRUM]: '0xA59B2044EAFD15ee4deF138D410d764c9023E1F0',
  [ChainId.FLARE]: ZERO_ADDRESS,
  [ChainId.FLARE_TESTNET]: ZERO_ADDRESS,
  [ChainId.FUSION]: ZERO_ADDRESS,
  [ChainId.HARMONY]: '0xCdde1AbfF5Ae3Cbfbdb55c1e866Ac56380e18720',
  [ChainId.OKEX]: '0x1116f8B82028324f2065078b4ff6b47F1Cc22B97',
  [ChainId.IOTEX]: '0xF96bE66DA0b9bC9DFD849827b4acfA7e8a6F3C42',
  [ChainId.HOO]: '0x9c03E724455306491BfD2CE0805fb872727313eA',
  [ChainId.ELAETH]: '0x440a1B8b8e968D6765D41E6b92DF3cBb0e9D2b1e',
  [ChainId.MOONRIVER]: '0xd45145f10fD4071dfC9fC3b1aefCd9c83A685e77',
  [ChainId.TELOS]: '0x47c3163e691966f8c1b93B308A236DDB3C1C592d',
  [ChainId.CRONOS]: '0xEEa0e2830D09D8786Cb9F484cA20898b61819ef1',
  [ChainId.KAVA]: '0xC012C4b3d253A8F22d5e4ADA67ea2236FF9778fc',
  [ChainId.BINANCE_TESTNET]: ZERO_ADDRESS,
  [ChainId.BINANCE]: '0x31aFfd875e9f68cd6Cd12Cee8943566c9A4bBA13',
  [ChainId.ROPSTEN]: ZERO_ADDRESS,
  [ChainId.ETHEREUM]: '0x6511eBA915fC1b94b2364289CCa2b27AE5898d80',
}

export const INIT_CODE_HASH: { [chainId in ChainId]: string } = {
  [ChainId.MUMBAI]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [ChainId.MATIC]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [ChainId.FUJI]: '0x33c4831a098654d3d20a78641a198ee6ffc1ceed49f2196b75bb244891c260e3',
  [ChainId.AVALANCHE]: '0x33c4831a098654d3d20a78641a198ee6ffc1ceed49f2196b75bb244891c260e3',
  [ChainId.FANTOM_TESTNET]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [ChainId.FANTOM]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [ChainId.HUOBIECO_TESTNET]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [ChainId.HUOBIECO]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [ChainId.REEF]: '',
  [ChainId.XDAI]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [ChainId.FUSE]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [ChainId.OPTIMISM]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [ChainId.OPTIMISM_TESTNET]: '',
  [ChainId.KUCOIN]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [ChainId.KUCOIN_TESTNET]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [ChainId.ETHERLITE]: '',
  [ChainId.ETHEREUM_CLASSIC]: '',
  [ChainId.ETHEREUM_CLASSIC_TESTNET]: '',
  [ChainId.MOONBEAM]: '',
  [ChainId.MOONBEAM_TESTNET]: '',
  [ChainId.CLOVER]: '',
  [ChainId.CLOVER_TESTNET]: '',
  [ChainId.CELO]: '',
  [ChainId.ALFAJORES]: '',
  [ChainId.ARBITRUM_TESTNET]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [ChainId.ARBITRUM]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [ChainId.FLARE]: '',
  [ChainId.FLARE_TESTNET]: '',
  [ChainId.FUSION]: '',
  [ChainId.HARMONY]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [ChainId.OKEX]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [ChainId.IOTEX]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [ChainId.HOO]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [ChainId.ELAETH]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [ChainId.MOONRIVER]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [ChainId.TELOS]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [ChainId.CRONOS]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [ChainId.KAVA]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [ChainId.BINANCE_TESTNET]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [ChainId.BINANCE]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [ChainId.ROPSTEN]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
  [ChainId.ETHEREUM]: '0x84845e7ccb283dec564acfcd3d9287a491dec6d675705545a2ab8be22ad78f31',
}

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const TWO = JSBI.BigInt(2)
export const THREE = JSBI.BigInt(3)
export const FIVE = JSBI.BigInt(5)
export const TEN = JSBI.BigInt(10)
export const _100 = JSBI.BigInt(100)
export const _997 = JSBI.BigInt(997)
export const _1000 = JSBI.BigInt(1000)

export enum SolidityType {
  uint8 = 'uint8',
  uint256 = 'uint256'
}

export const SOLIDITY_TYPE_MAXIMA = {
  [SolidityType.uint8]: JSBI.BigInt('0xff'),
  [SolidityType.uint256]: JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
}
