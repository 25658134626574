import invariant from 'tiny-invariant'
import {ChainId, ZERO_ADDRESS} from '../constants'
import { validateAndParseAddress } from '../utils'
import { Currency } from './currency'

/**
 * Represents an ERC20 token with a unique address and some metadata.
 */
export class Token extends Currency {
  //public readonly chainId: ChainId
  public readonly address: string

  public constructor(chainId: ChainId, address: string, decimals: number, symbol?: string, name?: string) {
    super(chainId, decimals, symbol, name)
    //this.chainId = chainId
    this.address = validateAndParseAddress(address)
  }

  /**
   * Returns true if the two tokens are equivalent, i.e. have the same chainId and address.
   * @param other other token to compare
   */
  public equals(other: Token): boolean {
    // short circuit on reference equality
    if (this === other) {
      return true
    }
    return this.chainId === other.chainId && this.address === other.address
  }

  /**
   * Returns true if the address of this token sorts before the address of the other token
   * @param other other token to compare
   * @throws if the tokens have the same address
   * @throws if the tokens are on different chains
   */
  public sortsBefore(other: Token): boolean {
    invariant(this.chainId === other.chainId, 'CHAIN_IDS')
    invariant(this.address !== other.address, 'ADDRESSES')
    return this.address.toLowerCase() < other.address.toLowerCase()
  }
}

/**
 * Compares two currencies for equality
 */
export function currencyEquals(currencyA: Currency, currencyB: Currency): boolean {
  if (currencyA instanceof Token && currencyB instanceof Token) {
    return currencyA.equals(currencyB)
  } else if (currencyA instanceof Token) {
    return false
  } else if (currencyB instanceof Token) {
    return false
  } else {
    return currencyA === currencyB
  }
}

export const WETH = {
  [ChainId.MUMBAI]: new Token(ChainId.MUMBAI,'0x5B67676a984807a212b1c59eBFc9B3568a474F0a',18,'WMATIC','Wrapped MATIC'),
  [ChainId.MATIC]: new Token(ChainId.MATIC,'0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',18,'WMATIC','Wrapped MATIC'),
  [ChainId.FUJI]: new Token(ChainId.FUJI,'0xd00ae08403B9bbb9124bB305C09058E32C39A48c',18,'WAVAX','Wrapped AVAX'),
  [ChainId.AVALANCHE]: new Token(ChainId.AVALANCHE,'0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',18,'WAVAX','Wrapped AVAX'),
  [ChainId.FANTOM_TESTNET]: new Token(ChainId.FANTOM_TESTNET,'0xf1277d1Ed8AD466beddF92ef448A132661956621',18,'WFTM','Wrapped FTM'),
  [ChainId.FANTOM]: new Token(ChainId.FANTOM,'0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',18,'WFTM','Wrapped FTM'),
  [ChainId.HUOBIECO_TESTNET]: new Token(ChainId.HUOBIECO_TESTNET,'0x7aF326B6351C8A9b8fb8CD205CBe11d4Ac5FA836',18,'WHT','Wrapped HT'),
  [ChainId.HUOBIECO]: new Token(ChainId.HUOBIECO,'0x5545153CCFcA01fbd7Dd11C0b23ba694D9509A6F',18,'WHT','Wrapped HT'),
  [ChainId.REEF]: new Token(ChainId.REEF, ZERO_ADDRESS,18,'WREEF','Wrapped REEF'),
  [ChainId.XDAI]: new Token(ChainId.XDAI, '0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d',18,'WXDAI','Wrapped XDAI'),
  [ChainId.FUSE]: new Token(ChainId.FUSE, '0x0BE9e53fd7EDaC9F859882AfdDa116645287C629',18,'WFUSE','Wrapped FUSE'),
  [ChainId.OPTIMISM]: new Token(ChainId.OPTIMISM, '0x4200000000000000000000000000000000000006',18,'WETH','Wrapped ETH'),
  [ChainId.OPTIMISM_TESTNET]: new Token(ChainId.OPTIMISM_TESTNET, ZERO_ADDRESS,18,'WKOR','Wrapped KOR'),
  [ChainId.KUCOIN]: new Token(ChainId.KUCOIN, '0x4446Fc4eb47f2f6586f9fAAb68B3498F86C07521',18,'WKCS','Wrapped KCS'),
  [ChainId.KUCOIN_TESTNET]: new Token(ChainId.KUCOIN_TESTNET, ZERO_ADDRESS,18,'WtKCS','Wrapped tKCS'),
  [ChainId.ETHERLITE]: new Token(ChainId.ETHERLITE, ZERO_ADDRESS,18,'WETL','Wrapped ETL'),
  [ChainId.ETHEREUM_CLASSIC]: new Token(ChainId.ETHEREUM_CLASSIC, ZERO_ADDRESS,18,'WETC','Wrapped ETC'),
  [ChainId.ETHEREUM_CLASSIC_TESTNET]: new Token(ChainId.ETHEREUM_CLASSIC_TESTNET, ZERO_ADDRESS,18,'WCETC','Wrapped CETC'),
  [ChainId.MOONBEAM]: new Token(ChainId.MOONBEAM, ZERO_ADDRESS,18,'WGLMR','Wrapped GLMR'),
  [ChainId.MOONBEAM_TESTNET]: new Token(ChainId.MOONBEAM_TESTNET, ZERO_ADDRESS,18,'WDEV','Wrapped DEV'),
  [ChainId.CLOVER]: new Token(ChainId.CLOVER, ZERO_ADDRESS,18,'WCLV','Wrapped CLV'),
  [ChainId.CLOVER_TESTNET]: new Token(ChainId.CLOVER_TESTNET, ZERO_ADDRESS,18,'WCLV','Wrapped CLV'),
  [ChainId.CELO]: new Token(ChainId.CELO, ZERO_ADDRESS,18,'WCELO','Wrapped CELO'),
  [ChainId.ALFAJORES]: new Token(ChainId.ALFAJORES, ZERO_ADDRESS,18,'WCELO','Wrapped CELO'),
  [ChainId.ARBITRUM_TESTNET]: new Token(ChainId.ARBITRUM_TESTNET, ZERO_ADDRESS,18,'WETH','Wrapped ETH'),
  [ChainId.ARBITRUM]: new Token(ChainId.ARBITRUM, '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',18,'WETH','Wrapped ETH'),
  [ChainId.FLARE]: new Token(ChainId.FLARE, ZERO_ADDRESS,18,'WFLR','Wrapped FLR'),
  [ChainId.FLARE_TESTNET]: new Token(ChainId.FLARE_TESTNET, ZERO_ADDRESS,18,'WCFLR','Wrapped CFLR'),
  [ChainId.FUSION]: new Token(ChainId.FUSION, ZERO_ADDRESS,18,'WFSN','Wrapped FSN'),
  [ChainId.HARMONY]: new Token(ChainId.HARMONY, '0xcF664087a5bB0237a0BAd6742852ec6c8d69A27a',18,'WONE','Wrapped ONE'),
  [ChainId.OKEX]: new Token(ChainId.OKEX, '0x8F8526dbfd6E38E3D8307702cA8469Bae6C56C15',18,'WOKT','Wrapped OKT'),
  [ChainId.IOTEX]: new Token(ChainId.IOTEX, '0xA00744882684C3e4747faEFD68D283eA44099D03',18,'WIOTX','Wrapped IOTX'),
  [ChainId.HOO]: new Token(ChainId.HOO, '0x3EFF9D389D13D6352bfB498BCF616EF9b1BEaC87',18,'WHOO','Wrapped HOO'),
  [ChainId.ELAETH]: new Token(ChainId.ELAETH, '0x517E9e5d46C1EA8aB6f78677d6114Ef47F71f6c4',18,'WELA','Wrapped ELA'),
  [ChainId.MOONRIVER]: new Token(ChainId.MOONRIVER, '0x98878B06940aE243284CA214f92Bb71a2b032B8A',18,'WMOVR','Wrapped MOVR'),
  [ChainId.TELOS]: new Token(ChainId.TELOS, '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',18,'WTLOS','Wrapped TLOS'),
  [ChainId.CRONOS]: new Token(ChainId.CRONOS, '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23',18,'WCRO','Wrapped CRO'),
  [ChainId.KAVA]: new Token(ChainId.KAVA,'0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b',18,'WKAVA','Wrapped KAVA'),
  [ChainId.BINANCE_TESTNET]: new Token(ChainId.BINANCE_TESTNET,'0x094616F0BdFB0b526bD735Bf66Eca0Ad254ca81F',18,'WBNB','Wrapped BNB'),
  [ChainId.BINANCE]: new Token(ChainId.BINANCE,'0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',18,'WBNB','Wrapped BNB'),
  [ChainId.ROPSTEN]: new Token(ChainId.ROPSTEN,'0x0a180A76e4466bF68A7F86fB029BEd3cCcFaAac5',18,'WETH','Wrapped ETH'),
  [ChainId.ETHEREUM]: new Token(ChainId.ETHEREUM,'0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',18,'WETH','Wrapped ETH'),
}
