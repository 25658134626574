import JSBI from 'jsbi'

import {ChainId, SolidityType} from '../constants'
import { validateSolidityTypeInstance } from '../utils'

/**
 * A currency is any fungible financial instrument on Ethereum, including Ether and all ERC20 tokens.
 *
 * The only instance of the base class `Currency` is Ether.
 */
export class Currency {
  public readonly decimals: number
  public readonly symbol?: string
  public readonly name?: string
  public readonly chainId: ChainId

  /**
   * The only instance of the base class `Currency`.
   */
  public static readonly CMATICMUMBAI: Currency = new Currency(ChainId.MUMBAI, 18, 'MATIC', 'Matic')
  public static readonly CMATIC: Currency = new Currency(ChainId.MATIC, 18, 'MATIC', 'Matic')
  public static readonly CAVAXFUJI: Currency = new Currency(ChainId.FUJI, 18, 'AVAX', 'Avalanche')
  public static readonly CAVAX: Currency = new Currency(ChainId.AVALANCHE, 18, 'AVAX', 'Avalanche')
  public static readonly CFTMTEST: Currency = new Currency(ChainId.FANTOM_TESTNET, 18, 'FTM', 'Fantom')
  public static readonly CFTM: Currency = new Currency(ChainId.FANTOM, 18, 'FTM', 'Fantom')
  public static readonly CHTTEST: Currency = new Currency(ChainId.HUOBIECO_TESTNET, 18, 'HT', 'Huobi ECO')
  public static readonly CHT: Currency = new Currency(ChainId.HUOBIECO, 18, 'HT', 'Huobi ECO')
  public static readonly CREEF: Currency = new Currency(ChainId.REEF, 18, 'REEF', 'Reef')
  public static readonly CXDAI: Currency = new Currency(ChainId.XDAI, 18, 'XDAI', 'xDai')
  public static readonly CFUSE: Currency = new Currency(ChainId.FUSE, 18, 'FUSE', 'Fuse')
  public static readonly COETH: Currency = new Currency(ChainId.OPTIMISM, 18, 'ETH', 'Optimism')
  public static readonly CKOR: Currency = new Currency(ChainId.OPTIMISM_TESTNET, 18, 'KOR', 'Optimism')
  public static readonly CKCS: Currency = new Currency(ChainId.KUCOIN, 18, 'KCS', 'Kucoin')
  public static readonly CtKCS: Currency = new Currency(ChainId.KUCOIN_TESTNET, 18, 'tKCS', 'Kucoin')
  public static readonly CETL: Currency = new Currency(ChainId.ETHERLITE, 18, 'ETL', 'Etherlite')
  public static readonly CETC: Currency = new Currency(ChainId.ETHEREUM_CLASSIC, 18, 'ETC', 'Ethereum Classic')
  public static readonly CCETC: Currency = new Currency(ChainId.ETHEREUM_CLASSIC_TESTNET, 18, 'CETC', 'Ethereum Classic')
  public static readonly CGLMR: Currency = new Currency(ChainId.MOONBEAM, 18, 'GLMR', 'Moonbeam')
  public static readonly CDEV: Currency = new Currency(ChainId.MOONBEAM_TESTNET, 18, 'DEV', 'Moonbeam')
  public static readonly CCLV: Currency = new Currency(ChainId.CLOVER, 18, 'CLV', 'Clover')
  public static readonly CCLVTEST: Currency = new Currency(ChainId.CLOVER_TESTNET, 18, 'CLV', 'Clover')
  public static readonly CCELO: Currency = new Currency(ChainId.CELO, 18, 'CELO', 'Celo')
  public static readonly CCELOTEST: Currency = new Currency(ChainId.ALFAJORES, 18, 'CELO', 'Celo')
  public static readonly CAETHTEST: Currency = new Currency(ChainId.ARBITRUM_TESTNET, 18, 'ETH', 'Arbitrum')
  public static readonly CAETH: Currency = new Currency(ChainId.ARBITRUM, 18, 'ETH', 'Arbitrum')
  public static readonly CFLR: Currency = new Currency(ChainId.FLARE, 18, 'FLR', 'Flare')
  public static readonly CCFLR: Currency = new Currency(ChainId.FLARE_TESTNET, 18, 'CFLR', 'Flare')
  public static readonly CFSN: Currency = new Currency(ChainId.FUSION, 18, 'FSN', 'Fusion')
  public static readonly CONE: Currency = new Currency(ChainId.HARMONY, 18, 'ONE', 'Harmony')
  public static readonly COKT: Currency = new Currency(ChainId.OKEX, 18, 'OKT', 'OKEx')
  public static readonly CIOTX: Currency = new Currency(ChainId.IOTEX, 18, 'IOTX', 'IoTeX')
  public static readonly CHOO: Currency = new Currency(ChainId.HOO, 18, 'HOO', 'HOO')
  public static readonly CELA: Currency = new Currency(ChainId.ELAETH, 18, 'ELA', 'ELA')
  public static readonly CMOVR: Currency = new Currency(ChainId.MOONRIVER, 18, 'MOVR', 'MOVR')
  public static readonly CTLOS: Currency = new Currency(ChainId.TELOS, 18, 'TLOS', 'TLOS')
  public static readonly CCRO: Currency = new Currency(ChainId.CRONOS, 18, 'CRO', 'CRO')
  public static readonly CKAVA: Currency = new Currency(ChainId.KAVA, 18, 'KAVA', 'Kava')
  public static readonly CBSCTEST: Currency = new Currency(ChainId.BINANCE_TESTNET, 18, 'BSC', 'Binance')
  public static readonly CBSC: Currency = new Currency(ChainId.BINANCE, 18, 'BNB', 'Binance')
  public static readonly CETHROPSTEN: Currency = new Currency(ChainId.ROPSTEN, 18, 'ETH', 'Ethereum')
  public static readonly CETH: Currency = new Currency(ChainId.ETHEREUM, 18, 'ETH', 'Ethereum')

  /**
   * Constructs an instance of the base class `Currency`. The only instance of the base class `Currency` is `Currency.ETHER`.
   * @param decimals decimals of the currency
   * @param symbol symbol of the currency
   * @param name of the currency
   */
  protected constructor(chainId: ChainId, decimals: number, symbol?: string, name?: string) {
    validateSolidityTypeInstance(JSBI.BigInt(decimals), SolidityType.uint8)

    this.chainId = chainId
    this.decimals = decimals
    this.symbol = symbol
    this.name = name
  }
}

export const CETH = {
  [ChainId.MUMBAI]: Currency.CMATICMUMBAI,
  [ChainId.MATIC]: Currency.CMATIC,
  [ChainId.FUJI]: Currency.CAVAXFUJI,
  [ChainId.AVALANCHE]: Currency.CAVAX,
  [ChainId.FANTOM_TESTNET]: Currency.CFTMTEST,
  [ChainId.FANTOM]: Currency.CFTM,
  [ChainId.HUOBIECO_TESTNET]: Currency.CHTTEST,
  [ChainId.HUOBIECO]: Currency.CHT,
  [ChainId.REEF]: Currency.CREEF,
  [ChainId.XDAI]: Currency.CXDAI,
  [ChainId.FUSE]: Currency.CFUSE,
  [ChainId.OPTIMISM]: Currency.COETH,
  [ChainId.OPTIMISM_TESTNET]: Currency.CKOR,
  [ChainId.KUCOIN]: Currency.CKCS,
  [ChainId.KUCOIN_TESTNET]: Currency.CtKCS,
  [ChainId.ETHERLITE]: Currency.CETL,
  [ChainId.ETHEREUM_CLASSIC]: Currency.CETC,
  [ChainId.ETHEREUM_CLASSIC_TESTNET]: Currency.CCETC,
  [ChainId.MOONBEAM]: Currency.CGLMR,
  [ChainId.MOONBEAM_TESTNET]: Currency.CDEV,
  [ChainId.CLOVER]: Currency.CCLV,
  [ChainId.CLOVER_TESTNET]: Currency.CCLVTEST,
  [ChainId.CELO]: Currency.CCELO,
  [ChainId.ALFAJORES]: Currency.CCELOTEST,
  [ChainId.ARBITRUM_TESTNET]: Currency.CAETHTEST,
  [ChainId.ARBITRUM]: Currency.CAETH,
  [ChainId.FLARE]: Currency.CFLR,
  [ChainId.FLARE_TESTNET]: Currency.CCFLR,
  [ChainId.FUSION]: Currency.CFSN,
  [ChainId.HARMONY]: Currency.CONE,
  [ChainId.OKEX]: Currency.COKT,
  [ChainId.IOTEX]: Currency.CIOTX,
  [ChainId.HOO]: Currency.CHOO,
  [ChainId.ELAETH]: Currency.CELA,
  [ChainId.MOONRIVER]: Currency.CMOVR,
  [ChainId.TELOS]: Currency.CTLOS,
  [ChainId.CRONOS]: Currency.CCRO,
  [ChainId.KAVA]: Currency.CKAVA,
  [ChainId.BINANCE_TESTNET]: Currency.CBSCTEST,
  [ChainId.BINANCE]: Currency.CBSC,
  [ChainId.ROPSTEN]: Currency.CETHROPSTEN,
  [ChainId.ETHEREUM]: Currency.CETH,
}

export const CETH_NAME = {
  [ChainId.MUMBAI]: 'MATIC',
  [ChainId.MATIC]: 'MATIC',
  [ChainId.FUJI]: 'AVAX',
  [ChainId.AVALANCHE]: 'AVAX',
  [ChainId.FANTOM_TESTNET]: 'FTM',
  [ChainId.FANTOM]: 'FTM',
  [ChainId.HUOBIECO_TESTNET]: 'HT',
  [ChainId.HUOBIECO]: 'HT',
  [ChainId.REEF]: 'REEF',
  [ChainId.XDAI]: 'xDAI',
  [ChainId.FUSE]: 'FUSE',
  [ChainId.OPTIMISM]: 'ETH',
  [ChainId.OPTIMISM_TESTNET]: 'KOR',
  [ChainId.KUCOIN]: 'KCS',
  [ChainId.KUCOIN_TESTNET]: 'tKCS',
  [ChainId.ETHERLITE]: 'ETL',
  [ChainId.ETHEREUM_CLASSIC]: 'ETC',
  [ChainId.ETHEREUM_CLASSIC_TESTNET]: 'CETC',
  [ChainId.MOONBEAM]: 'GLMR',
  [ChainId.MOONBEAM_TESTNET]: 'DEV',
  [ChainId.CLOVER]: 'CLV',
  [ChainId.CLOVER_TESTNET]: 'CLV',
  [ChainId.CELO]: 'CELO',
  [ChainId.ALFAJORES]: 'CELO',
  [ChainId.ARBITRUM_TESTNET]: 'ETH',
  [ChainId.ARBITRUM]: 'ETH',
  [ChainId.FLARE]: 'FLR',
  [ChainId.FLARE_TESTNET]: 'CFLR',
  [ChainId.FUSION]: 'FSN',
  [ChainId.HARMONY]: 'ONE',
  [ChainId.OKEX]: 'OKT',
  [ChainId.IOTEX]: 'IOTX',
  [ChainId.HOO]: 'HOO',
  [ChainId.ELAETH]: 'ELA',
  [ChainId.MOONRIVER]: 'MOVR',
  [ChainId.TELOS]: 'TLOS',
  [ChainId.CRONOS]: 'CRO',
  [ChainId.KAVA]: 'KAVA',
  [ChainId.BINANCE_TESTNET]: 'BNB',
  [ChainId.BINANCE]: 'BNB',
  [ChainId.ROPSTEN]: 'ETH',
  [ChainId.ETHEREUM]: 'ETH'
}

export const CETH_NAME_ROUTER = {
  [ChainId.MUMBAI]: 'MATIC',
  [ChainId.MATIC]: 'MATIC',
  [ChainId.FUJI]: 'AVAX',
  [ChainId.AVALANCHE]: 'AVAX',
  [ChainId.FANTOM_TESTNET]: 'FTM',
  [ChainId.FANTOM]: 'FTM',
  [ChainId.HUOBIECO_TESTNET]: 'HT',
  [ChainId.HUOBIECO]: 'HT',
  [ChainId.REEF]: 'ETH',
  [ChainId.XDAI]: 'xDAI',
  [ChainId.FUSE]: 'ETH',
  [ChainId.OPTIMISM]: 'ETH',
  [ChainId.OPTIMISM_TESTNET]: 'ETH',
  [ChainId.KUCOIN]: 'ETH',
  [ChainId.KUCOIN_TESTNET]: 'ETH',
  [ChainId.ETHERLITE]: 'ETH',
  [ChainId.ETHEREUM_CLASSIC]: 'ETH',
  [ChainId.ETHEREUM_CLASSIC_TESTNET]: 'ETH',
  [ChainId.MOONBEAM]: 'ETH',
  [ChainId.MOONBEAM_TESTNET]: 'ETH',
  [ChainId.CLOVER]: 'ETH',
  [ChainId.CLOVER_TESTNET]: 'ETH',
  [ChainId.CELO]: 'ETH',
  [ChainId.ALFAJORES]: 'ETH',
  [ChainId.ARBITRUM_TESTNET]: 'ETH',
  [ChainId.ARBITRUM]: 'ETH',
  [ChainId.FLARE]: 'ETH',
  [ChainId.FLARE_TESTNET]: 'ETH',
  [ChainId.FUSION]: 'ETH',
  [ChainId.HARMONY]: 'ETH',
  [ChainId.OKEX]: 'ETH',
  [ChainId.IOTEX]: 'ETH',
  [ChainId.HOO]: 'ETH',
  [ChainId.ELAETH]: 'ETH',
  [ChainId.MOONRIVER]: 'ETH',
  [ChainId.TELOS]: 'ETH',
  [ChainId.CRONOS]: 'ETH',
  [ChainId.KAVA]: 'ETH',
  [ChainId.BINANCE_TESTNET]: 'BNB',
  [ChainId.BINANCE]: 'BNB',
  [ChainId.ROPSTEN]: 'ETH',
  [ChainId.ETHEREUM]: 'ETH'
}
